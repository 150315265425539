import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Container from "~components/common/Container";
import Toggle from "~components/forms/elements/Toggle";
import Grid from "~components/common/Grid";
import SchemaProduct from "~components/layouts/schemas/SchemaProduct";
import H2 from "~components/typography/H2";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";
import PriceCardItemAlt from "./elements/PriceCardItemAlt";

function WebdesignPlansAlt({ title, description, onClick, keyword, link, image, url, aboToggle = false }) {
	const [abo, setAbo] = useState(aboToggle);

	const standardFeauteres = ["Und viele weitere Lösungen, die zu einer Komplett-Lösung beitragen"];

	const monthlyContractsStandard = [
		"Keine Mindestvertragslaufzeit",
		"Keine weiteren Kosten bei Änderungen der Website",
	];

	const tiers = [
		{
			name: "Landingpage",
			price: 1140,
			priceMonthly: 60,
			contractMonthly: ["Einmalige Einrichtungsgebühr: 299,- € netto"].concat(monthlyContractsStandard),
			description: "Eine einfache einseitige Website. Perfekt als Landingpage für Werbeanzeigen.",
			includedFeatures: [
				"Eine individuelle statische Seite ohne Unterseiten",
				"Einfaches Anfrageformular",
				"Einrichtung Google Analytics",
			].concat(standardFeauteres),
		},
		{
			name: "Starter",
			price: 2400,
			priceMonthly: 100,
			contractMonthly: ["Einmalige Einrichtungsgebühr: 399,- € netto"].concat(monthlyContractsStandard),
			description: "Eine Website mit bis zu fünf Unterseiten. Perfekt für Start-ups und kleine Unternehmen.",
			includedFeatures: [
				"Bis zu fünf statische Seiten",
				"Eine CMS-Seite (z.B. ein Blog)",
				"Multi-Step Anfrageformulare",
			].concat(standardFeauteres),
		},
		{
			name: "Business",
			price: 4800,
			priceMonthly: 200,
			contractMonthly: ["Einmalige Einrichtungsgebühr: 499,- € netto"].concat(monthlyContractsStandard),
			description: "Eine Website mit bis zu 15 Unterseiten. Perfekt für größere Projekte.",
			includedFeatures: [
				"Bis zu 15 statische Seiten",
				"Eine CMS-Seite (z.B. ein Blog)",
				"Multi-Step Anfrageformulare",
				"Newsletter-Integration",
			].concat(standardFeauteres),
		},
	];

	const options = [
		{
			name: "Online-Shop bis zu 20 Produkte",
			price: 480,
			priceMonthly: 20,
			description:
				"Wir erweitern Deine Website durch einen Online-Shop. Als Shopify Partner nutzen wir den Cloud-Service als Headless-Shop und können so nahtlos die Shopify Funktionen in Deine Website integrieren. Die monatliche Gebühr für Shopify ist nicht enthalten.",
		},
		{
			name: "Statische Zusatzseite",
			price: 240,
			priceMonthly: 10,
			description: (
				<>
					Zusätzliche individuell gestaltete statische Seite, falls zwölf Unterseiten nicht ausreichen. Jede einzelne
					Seite wird mit Liebe gestaltet und kennzeichnet sich durch ein einzigartiges Auftreten.
				</>
			),
		},
		{
			name: "CMS Zusatzseite",
			price: 480,
			priceMonthly: 20,
			description: (
				<>
					Zusätzliche individuell gestaltete CMS-Seite, falls eine Seite nicht ausreicht. CMS-Seiten können unendlich
					groß skalieren und werden von Dir oder wahlweise von uns über ein selbst entwickeltes CMS-System gepflegt.
				</>
			),
		},
		{
			name: "Visuelles CMS",
			price: 960,
			priceMonthly: 40,
			description: (
				<>
					Wir erweitern Deine Website durch ein visuelles Content-Management-System. Als Storyblok Partner nutzen wir
					den Cloud-Service als{" "}
					<Link to="/blog/headless-cms-entkoppelte-architekturen-fuer-website-und-app/" className="underline">
						Headless-CMS
					</Link>{" "}
					und können so nahtlos einen visuellen Website-Builder in Deine Website integrieren. Die monatliche Gebühr für
					Storyblok ist nicht enthalten.
				</>
			),
		},
		{
			name: "Zusatzfunktionen",
			description: (
				<>
					Funktionen wie Buchungstools oder Mitgliederbereiche müssen individuell besprochen werden und können je nach
					Anforderung in die monatliche Rate integriert werden oder über eine Einmalzahlung abgelöst werden.
				</>
			),
		},
	];

	return (
		<Container id="preise">
			<SchemaProduct
				name={title}
				image={image}
				description={description}
				aggregateOffer
				lowPrice="60"
				highPrice="4800"
				url={url}
			/>

			<div className="max-w-2xl mx-auto mb-8 text-center">
				<H2 display className="mb-5 font-display">
					Preise{keyword && `: ${keyword}`}
				</H2>
				<div>
					<P className="mb-3">
						Die Developer Experience unseres Systems ermöglicht es uns {keyword ? `${keyword}` : "Webdesign"}{" "}
						anzubieten, welches schneller und präziser entwickelt werden kann als übliche Websites. Dadurch können wir
						Basispakete anbieten, welche die Anforderungen einer einfachen Website erfüllen und dennoch günstiger sind,
						als übliche Seiten. Zudem bieten wir unsere Websites auch im Abo an. Dort sind jegliche{" "}
						<span className="font-bold">Änderungen an der Website inklusive</span>.
					</P>

					<div className="flex justify-center">
						<Toggle
							label="Website im Abo"
							onChange={() => setAbo((prevState) => !prevState)}
							value={abo}
							className="mx-auto"
						/>
					</div>
				</div>
			</div>

			<Grid cols={3} itemsStart>
				{tiers.map((tier) => (
					<PriceCardItemAlt
						name={tier.name}
						description={tier.description}
						price={abo ? tier.priceMonthly : tier.price}
						contract={abo ? tier.contractMonthly : null}
						monthly={abo ? true : false}
						features={tier.includedFeatures}
						onClick={onClick}
						link={link}
						key={tier.name}
					/>
				))}
			</Grid>
			<Grid cols={3} className="mt-8">
				<div>
					<H2 as="p" className="mb-5 font-display">
						Zusatzoptionen
					</H2>
					<P>
						Die Pakete passen nicht mehr, oder Du brauchst Zusatzfunktionen{keyword && ` für ${keyword}`}, die nicht in
						einem der Pakete abgebildet sind? Für uns ist das kein Problem! Mit den Zusatzoptionen können wir jedes
						Angebot durch weitere Zusatzleistungen erweitern. So bleibt Deine Website immer zukunftssicher.
					</P>
				</div>
				<div className="md:col-span-2">
					<ul className="grid items-start grid-cols-1 gap-3 md:grid-cols-2">
						{options.map((option) => (
							<li className={`bg-pattern p-2 ${option.span === 2 ? "md:col-span-2" : ""}`} key={option.name}>
								<div className="p-3 bg-white">
									<H3 as="p">{option.name}</H3>
									<P className="mt-4 text-sm" style={{ minHeight: "4rem" }}>
										{option.description}
									</P>
									<P className="my-4">
										{option.price && option.priceMonthly ? (
											<span className="text-2xl font-bold leading-none">
												{abo ? option.priceMonthly : option.price} €
												<span className="text-sm font-normal text-gray-700"> / netto {abo && "pro Monat"}</span>
											</span>
										) : (
											<span className="text-2xl font-bold leading-none">Auf Anfrage</span>
										)}
									</P>
								</div>
							</li>
						))}
					</ul>
				</div>
			</Grid>
		</Container>
	);
}

export default WebdesignPlansAlt;

WebdesignPlansAlt.propTypes = {
	onClick: PropTypes.func,
	link: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	keyword: PropTypes.string,
};

WebdesignPlansAlt.defaultProps = {
	onClick: null,
	link: null,
	keyword: null,
};
