import { CheckIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types";
import React from "react";
import Container from "~components/common/Container";
import Divider from "~components/common/Divider";
import Grid from "~components/common/Grid";
import SchemaProduct from "~components/layouts/schemas/SchemaProduct";
import H2 from "~components/typography/H2";
import H3 from "~components/typography/H3";
import NumberedSection from "~components/typography/NumberedSection";
import P from "~components/typography/P";
import PriceCardItem from "./elements/PriceCardItem";
import ReviewSlider from "~components/features/ReviewSlider";

const features = [
	"Konzeption und Wireframing ohne Baukasten",
	"Webdesign und Layout",
	"Erstellung einfacher Text",
	"Optionale Stockfotos",
	"Einfache Suchmaschinenoptimierung",
	"Live-Schaltung und Hosting",
	"Einrichtung Google Analytics",
	"Anmeldung bei der Google Search Console",
	"Extrem schnelle Website",
	"Zufriedenheitsgarantie - sonst Geld zurück",
	"Keine Mindestvertragslaufzeit und monatlich kündbar",
	"Alle Änderungen während der Laufzeit inklusive",
];

const tiers = [
	{
		name: "Landingpage",
		price: 60,
		contract: ["Einmalige Einrichtungsgebühr: 299,- € netto", "Keine Mindestvertragslaufzeit"],
		description: "Eine einfache einseitige Website. Perfekt als Landingpage für Werbeanzeigen.",
		includedFeatures: [
			"Eine individuelle statische Seite ohne Unterseiten",
			"Einfaches Anfrageformular",
			"Einrichtung Google Analytics",
		],
	},
	{
		name: "Starter",
		price: 100,
		contract: ["Einmalige Einrichtungsgebühr: 399,- € netto", "Keine Mindestvertragslaufzeit"],
		description: "Eine Website mit bis zu fünf Unterseiten. Perfekt für Start-ups und kleine Unternehmen.",
		includedFeatures: [
			"Bis zu fünf statische Seiten",
			"Eine CMS-Seite (z.B. ein Blog)",
			"Multi-Step Anfrageformulare",
		],
	},
	{
		name: "Business",
		price: 200,
		contract: ["Einmalige Einrichtungsgebühr: 499,- € netto", "Keine Mindestvertragslaufzeit"],
		description: "Eine Website mit bis zu zwölf Unterseiten. Perfekt für größere Projekte.",
		includedFeatures: [
			"Bis zu zwölf statische Seiten",
			"Eine CMS-Seite (z.B. ein Blog)",
			"Multi-Step Anfrageformulare",
			"Newsletter-Integration",
		],
	},
];
const options = [
	{
		name: "Online-Shop bis zu 20 Produkte",
		price: "20,- €",
		priceSub: "netto pro Monat",
		description: (
			<>
				Wir erweitern Deine Website durch einen Online-Shop. Als Shopify Partner nutzen wir den Cloud-Service als
				Headless-Shop und können so nahtlos die Shopify Funktionen in Deine Website integrieren. Die monatliche Gebühr
				für Shopify ist nicht enthalten.
			</>
		),
	},
	{
		name: "Online Werbung",
		price: "10 %",
		priceSub: "des Werbebudgets",
		description: <>Wir erstellen und verwalten Deine Werbeanzeigen bei Google und Facebook.</>,
	},
	{
		name: "Statische Zusatzseite",
		price: "10,- €",
		priceSub: "netto pro Monat",
		description: <>Zusätzliche individuell gestaltete statische Seite, falls zwölf Unterseiten nicht ausreichen.</>,
	},
	{
		name: "CMS Zusatzseite",
		price: "20,- €",
		priceSub: "netto pro Monat",
		description: <>Zusätzliche individuell gestaltete CMS-Seite, falls eine Seite nicht ausreicht.</>,
	},
	{
		name: "Visuelles CMS",
		price: "40,- €",
		priceSub: "netto pro Monat",
		description: (
			<>
				Wir erweitern Deine Website durch ein visuelles Content-Management-System. Als Storyblok Partner nutzen wir den
				Cloud-Service als{" "}
				<Link to="/blog/headless-cms-entkoppelte-architekturen-fuer-website-und-app/" className="underline">
					Headless-CMS
				</Link>{" "}
				und können so nahtlos einen visuellen Website-Builder in Deine Website integrieren. Die monatliche Gebühr für
				Storyblok ist nicht enthalten.
			</>
		),
	},
	{
		name: "Zusatzfunktionen",
		price: "Auf Anfrage",
		description: (
			<>
				Funktionen wie Buchungstools oder Mitgliederbereiche müssen individuell besprochen werden und können je nach
				Anforderung in die monatliche Rate integriert werden oder über eine Einmalzahlung abgelöst werden.
			</>
		),
	},
];

function AboPlans({ onClick, image, url, onlyCards, number }) {
	const isSSR = typeof window === "undefined";

	return (
		<Container noPadding className="my-8">
			<SchemaProduct
				name="Website Abo"
				image={image}
				description="Dein Website-Abo ab 60 € pro Monat für erfolgreiche Internetseiten. Wir planen und gestalten Deine Website individuell. ▻ Geringe Rate & geringe Investition!"
				aggregateOffer
				lowPrice="60"
				highPrice="200"
				url={url}
			/>
			<Divider className="mb-4" />
			<NumberedSection text="Kostentransparenz" number={number} className="mb-5" />
			<H2 display className="mb-5 font-display">
				Preise
			</H2>
			<Grid cols={2} className="mb-16" itemsCenter>
				<div>
					<P className="mb-3">
						Die Developer Experience unseres Systems ermöglicht es uns Webdesign anzubieten, welches schneller und
						präziser entwickelt werden kann als übliche Websites. Dadurch können wir Basispakete auf monatlicher Basis
						anbieten, welche die Anforderungen von einer guten Website erfüllen und dennoch günstiger sind, als übliche
						Seiten.
					</P>
					<P>
						Der Service-Aspekt ist uns extrem wichtig, deshalb ist bei jedem der Pakete schon inklusive. So zahlst Du
						eine monatliche Gebühr für Deine Website und <strong>wir kümmern uns dauerhaft um Dein Projekt</strong>.
					</P>
				</div>

				<ReviewSlider />
			</Grid>

			<Grid cols={3} itemsStart id="preise">
				{tiers.map((tier) => (
					<PriceCardItem
						name={tier.name}
						description={tier.description}
						price={tier.price}
						initalPrice={tier.initalPrice}
						contract={tier.contract}
						monthly
						standardFeature={
							<AnchorLink to="/leistungen/website-abo/#features" title="Alle Basis-Leistungen" className="underline" />
						}
						features={tier.includedFeatures}
						onClick={onClick}
						key={tier.name}
					/>
				))}
			</Grid>
			{onlyCards ? null : (
				<>
					<Grid cols={3} className="mt-16">
						<div id="features">
							<NumberedSection as="h3" text="Full Service" className="mb-5" />
							<P className="mt-2 text-3xl">Beauftragen, Besprechen und Online: unsere Basis-Leistungen</P>
							<P className="mt-4">
								Bei uns gibt es keine halben Sachen. Mit dem Erstellen der Website, erhältst Du bei uns ein Basis-Paket
								ohne Zusatzkosten, welches alle relevanten Dienstleistungen enthält, sodass Deine Website direkt online
								gehen kann.
							</P>
						</div>
						<div className="col-span-2">
							<ul className="grid gap-3 sm:grid-cols-2">
								{features.map((feature) => (
									<li key={feature} className="flex py-4">
										<CheckIcon className="h-6 w-6 shrink-0 text-green-500" aria-hidden="true" />
										<span className="ml-3 text-sm">{feature}</span>
									</li>
								))}
							</ul>
						</div>
					</Grid>
					<Grid cols={3} className="mt-16">
						<div>
							<NumberedSection text="Zukunftssichere Websites" className="mb-5" />
							<P className="mt-2 text-3xl" leading="leading-tight">
								Zusatzoptionen
							</P>
							<P className="mt-4">
								Die Pakete passen nicht mehr, oder Du brauchst Zusatzfunktionen, die nicht in einem der Pakete
								abgebildet sind? Für uns ist das kein Problem! Mit den Zusatzoptionen, können wir jedes Abo-Modell durch
								weitere Zusatzleistungen erweitern. So bleibt Deine Website immer zukunftssicher.
							</P>
						</div>
						<div className="md:col-span-2">
							<ul className="grid grid-cols-1 items-start gap-3 md:grid-cols-2">
								{options.map((option) => (
									<li className={`bg-pattern p-2 ${option.span === 2 ? "md:col-span-2" : ""}`} key={option.name}>
										<div className="bg-white p-3">
											<H3>{option.name}</H3>
											<P className="mt-4 text-sm" style={{ minHeight: "4rem" }}>
												{option.description}
											</P>
											<P className="my-4">
												<span className="text-2xl font-bold leading-none">
													{option.price}
													<span className="text-sm font-normal text-gray-700">
														{option.priceSub && <> / {option.priceSub}</>}
													</span>
												</span>
											</P>
										</div>
									</li>
								))}
							</ul>
						</div>
					</Grid>
				</>
			)}
		</Container>
	);
}

export default AboPlans;

AboPlans.propTypes = {
	onClick: PropTypes.func.isRequired,
	image: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	onlyCards: PropTypes.bool,
	number: PropTypes.string,
};

AboPlans.defaultProps = {
	onlyCards: false,
	number: "01",
};
